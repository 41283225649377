
import { defineComponent } from "vue";
import { isMB } from "@/core/directive/function/user";

export default defineComponent({
  name: "user-operating-entity",
  setup() {
    return {
      isMB,
    };
  },
});
