
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import SalesOrderOrderOverviewImport from "@/views/sales-order/order-overview/CommonImport.vue";
import UserOperatingEntity from "@/components/layout/UserOperatingEntity.vue";
import { setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { isMB } from "@/core/directive/function/user";
export default defineComponent({
  name: "sales-order-order-overview-import",
  components: { SalesOrderOrderOverviewImport, UserOperatingEntity },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const getDoubleSaleValue = computed(() => {
      if (isMB.value) {
        return 14;
      } else {
        return 2;
      }
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });
    return {
      getDoubleSaleValue,
    };
  },
});
